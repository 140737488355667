exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-our-products-index-de-js": () => import("./../../../src/pages/our-products/index.de.js" /* webpackChunkName: "component---src-pages-our-products-index-de-js" */),
  "component---src-pages-our-products-index-js": () => import("./../../../src/pages/our-products/index.js" /* webpackChunkName: "component---src-pages-our-products-index-js" */),
  "component---src-pages-our-products-index-lv-js": () => import("./../../../src/pages/our-products/index.lv.js" /* webpackChunkName: "component---src-pages-our-products-index-lv-js" */),
  "component---src-pages-projects-index-da-js": () => import("./../../../src/pages/projects/index.da.js" /* webpackChunkName: "component---src-pages-projects-index-da-js" */),
  "component---src-pages-projects-index-de-js": () => import("./../../../src/pages/projects/index.de.js" /* webpackChunkName: "component---src-pages-projects-index-de-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-index-lv-js": () => import("./../../../src/pages/projects/index.lv.js" /* webpackChunkName: "component---src-pages-projects-index-lv-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-airbaltic-case-study-js": () => import("./../../../src/templates/airbaltic-case-study.js" /* webpackChunkName: "component---src-templates-airbaltic-case-study-js" */),
  "component---src-templates-austra-erp-case-study-js": () => import("./../../../src/templates/austra-erp-case-study.js" /* webpackChunkName: "component---src-templates-austra-erp-case-study-js" */),
  "component---src-templates-axway-js": () => import("./../../../src/templates/axway.js" /* webpackChunkName: "component---src-templates-axway-js" */),
  "component---src-templates-balta-case-study-js": () => import("./../../../src/templates/balta-case-study.js" /* webpackChunkName: "component---src-templates-balta-case-study-js" */),
  "component---src-templates-batis-case-study-js": () => import("./../../../src/templates/batis-case-study.js" /* webpackChunkName: "component---src-templates-batis-case-study-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-careers-page-js": () => import("./../../../src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contacts-page-js": () => import("./../../../src/templates/contacts-page.js" /* webpackChunkName: "component---src-templates-contacts-page-js" */),
  "component---src-templates-cookie-policy-page-js": () => import("./../../../src/templates/cookie-policy-page.js" /* webpackChunkName: "component---src-templates-cookie-policy-page-js" */),
  "component---src-templates-developer-test-form-page-js": () => import("./../../../src/templates/developer-test-form-page.js" /* webpackChunkName: "component---src-templates-developer-test-form-page-js" */),
  "component---src-templates-developer-test-page-js": () => import("./../../../src/templates/developer-test-page.js" /* webpackChunkName: "component---src-templates-developer-test-page-js" */),
  "component---src-templates-edlus-case-study-js": () => import("./../../../src/templates/edlus-case-study.js" /* webpackChunkName: "component---src-templates-edlus-case-study-js" */),
  "component---src-templates-enme-case-study-js": () => import("./../../../src/templates/enme-case-study.js" /* webpackChunkName: "component---src-templates-enme-case-study-js" */),
  "component---src-templates-eyp-case-study-js": () => import("./../../../src/templates/eyp-case-study.js" /* webpackChunkName: "component---src-templates-eyp-case-study-js" */),
  "component---src-templates-fitsful-case-study-js": () => import("./../../../src/templates/fitsful-case-study.js" /* webpackChunkName: "component---src-templates-fitsful-case-study-js" */),
  "component---src-templates-food-union-driver-js": () => import("./../../../src/templates/food-union-driver.js" /* webpackChunkName: "component---src-templates-food-union-driver-js" */),
  "component---src-templates-golf-page-js": () => import("./../../../src/templates/golf-page.js" /* webpackChunkName: "component---src-templates-golf-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-kcs-case-study-js": () => import("./../../../src/templates/kcs-case-study.js" /* webpackChunkName: "component---src-templates-kcs-case-study-js" */),
  "component---src-templates-latvijas-notars-case-study-js": () => import("./../../../src/templates/latvijas-notars-case-study.js" /* webpackChunkName: "component---src-templates-latvijas-notars-case-study-js" */),
  "component---src-templates-leis-case-study-js": () => import("./../../../src/templates/leis-case-study.js" /* webpackChunkName: "component---src-templates-leis-case-study-js" */),
  "component---src-templates-library-page-js": () => import("./../../../src/templates/library-page.js" /* webpackChunkName: "component---src-templates-library-page-js" */),
  "component---src-templates-model-page-js": () => import("./../../../src/templates/model-page.js" /* webpackChunkName: "component---src-templates-model-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("./../../../src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-product-owner-page-js": () => import("./../../../src/templates/product-owner-page.js" /* webpackChunkName: "component---src-templates-product-owner-page-js" */),
  "component---src-templates-project-assistant-page-js": () => import("./../../../src/templates/project-assistant-page.js" /* webpackChunkName: "component---src-templates-project-assistant-page-js" */),
  "component---src-templates-recruiting-privacy-policy-page-js": () => import("./../../../src/templates/recruiting-privacy-policy-page.js" /* webpackChunkName: "component---src-templates-recruiting-privacy-policy-page-js" */),
  "component---src-templates-redirect-js": () => import("./../../../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-roboeatz-case-study-js": () => import("./../../../src/templates/roboeatz-case-study.js" /* webpackChunkName: "component---src-templates-roboeatz-case-study-js" */),
  "component---src-templates-senior-android-page-js": () => import("./../../../src/templates/senior-android-page.js" /* webpackChunkName: "component---src-templates-senior-android-page-js" */),
  "component---src-templates-senior-ios-page-js": () => import("./../../../src/templates/senior-ios-page.js" /* webpackChunkName: "component---src-templates-senior-ios-page-js" */),
  "component---src-templates-senior-react-native-page-js": () => import("./../../../src/templates/senior-react-native-page.js" /* webpackChunkName: "component---src-templates-senior-react-native-page-js" */),
  "component---src-templates-services-page-js": () => import("./../../../src/templates/services-page.js" /* webpackChunkName: "component---src-templates-services-page-js" */),
  "component---src-templates-soma-case-study-js": () => import("./../../../src/templates/soma-case-study.js" /* webpackChunkName: "component---src-templates-soma-case-study-js" */),
  "component---src-templates-vacancies-page-js": () => import("./../../../src/templates/vacancies-page.js" /* webpackChunkName: "component---src-templates-vacancies-page-js" */),
  "component---src-templates-zolt-case-study-js": () => import("./../../../src/templates/zolt-case-study.js" /* webpackChunkName: "component---src-templates-zolt-case-study-js" */)
}

